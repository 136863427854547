import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { useLocation, useNavigate } from 'react-router-dom'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const navigate = useNavigate()
    const layoutType = useSelector((state) => state.theme.layout.type)
    const userInfo = useSelector((state) => state.auth.user)
    const { authenticated, checkPermission } = useAuth()
    const location = useLocation()

    useEffect(() => {
        if (userInfo.is_newuser) {
            authenticated && navigate('/set-new-password')
        } else if (
            userInfo.groups === 'super-user' &&
            location.pathname.split('/')[1] === 'manage-invoices' &&
            location.pathname.split('/')[
                location.pathname.split('/').length - 1
            ] === 'create'
        ) {
            authenticated && navigate('/dashboard')
        } else if (
            !userInfo.is_newuser &&
            location.pathname.includes('set-new-password')
        ) {
            authenticated && navigate('/dashboard')
        } else if (
            checkPermission(
                location.pathname.split('/')[
                    location.pathname.split('/').length - 1
                ],
                ''
            )
        ) {
            // navigate(location.pathname)
        } else if (
            checkPermission(
                location.pathname.split('/')[1],
                location.pathname.split('/')[
                    location.pathname.split('/').length - 1
                ]
            )
        ) {
            navigate(location.pathname)
        } else if (
            location.pathname.split('/')[
                location.pathname.split('/').length - 1
            ] === 'reconcile'
        ) {
            navigate(location.pathname)
        } else if (location.pathname.split('/')[1] === 'invoices-history') {
            navigate(location.pathname)
        } else {
            authenticated && navigate('/dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (authenticated && !location.pathname.includes('set-new-password')) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated, location.pathname])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
