import store from 'store'
import { onSignOutSuccess } from 'store/auth/sessionSlice'

export function numberFormat(
    value,
    currency,
    digits = 2,
    style = 'currency' // decimal, currency, percent, unit
) {
    value = style === 'percent' ? value / 100 : value
    return new Intl.NumberFormat('en-US', {
        style: style,
        currency: currency ? currency : 'USD',
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    }).format(value)
}

export const apiError = (error) => {
    let text = null
    const { response, message } = error

    if (response) {
        const { status, data, message } = response

        if (status === 401) {
            store.dispatch(onSignOutSuccess())
        }
        if (status === 404) {
            text = '404 Not Found'
        } else if (status === 405) {
            text = '405 Method Not Allowed'
        } else if (status === 500) {
            text = '500 Internal Server Error'
        } else if (data) {
            Object.keys(data).map((key) => {
                if (Array.isArray(data[key])) {
                    data[key].map((e) => {
                        if (text === null) {
                            text = e
                        }
                        return e
                    })
                } else {
                    if (text === null) {
                        text = data[key]
                    }
                }
                return key
            })
        } else if (message) text = message
    } else if (message) text = message

    if (text) return text
}
