import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiLogIn, apiSignOut, getApiProfile } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import data from '../../assets/data/permissionData.json'
const { REACT_APP_URL } = process.env

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)
    const role = useSelector((state) => state.auth.user.groups)

    const signIn = async (values) => {
        try {
            const resp = await apiLogIn(values)
            if (resp.data) {
                const { access } = resp.data
                dispatch(onSignInSuccess(access))
                getApiProfile(resp.data.id)
                    .then((response) => {
                        resp.data.user = {
                            id: resp.data?.id,
                            first_name: resp.data?.first_name,
                            last_name: resp.data?.last_name,
                            email: resp.data?.email,
                            groups: resp.data?.groups,
                            is_newuser: resp.data?.is_newuser,
                            company_or_vendor:
                                resp.data?.company_or_vendor ?? '',
                            profile_image: `${REACT_APP_URL}${response.data?.profile_image}`,
                            currency_code: resp.data?.currency_code,
                        }
                        if (resp.data.user) {
                            dispatch(
                                setUser(
                                    resp.data.user || {
                                        avatar: '',
                                        userName: 'Anonymous',
                                        authority: ['USER'],
                                        email: '',
                                    }
                                )
                            )
                        }
                    })
                    .catch((error) => {
                        console.log('Error: ', error)
                        resp.data.user = {
                            id: resp.data?.id,
                            first_name: resp.data?.first_name,
                            last_name: resp.data?.last_name,
                            email: resp.data?.email,
                            groups: resp.data?.groups,
                            is_newuser: resp.data?.is_newuser,
                            company_or_vendor:
                                resp.data?.company_or_vendor ?? '',
                            profile_image: '',
                            currency_code: resp.data?.currency_code,
                        }
                        if (resp.data.user) {
                            dispatch(
                                setUser(
                                    resp.data.user || {
                                        avatar: '',
                                        userName: 'Anonymous',
                                        authority: ['USER'],
                                        email: '',
                                    }
                                )
                            )
                        }
                    })
                const redirectUrl = resp.data?.is_newuser
                    ? '/set-new-password'
                    : query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                    is_newuser: resp.data.is_newuser,
                }
            }
        } catch (error) {
            console.log('Error: ', error)
            return {
                status: 'failed',
                message: error?.response?.data?.detail || error.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        try {
            await apiSignOut()
            handleSignOut()
            return {
                status: 'success',
                message: '',
            }
        } catch (error) {
            console.log('Error: ', error)
            return {
                status: 'failed',
                message: error?.response?.data,
            }
        }
    }

    const checkPermission = (module, action) => {
        if (role === 'super-user') return true
        let permissionsData = []
        let status = false
        if (role) {
            data.map((item) => {
                if (item.role === role) {
                    permissionsData = item.permissions
                }
                return item
            })
        }

        permissionsData.map((item) => {
            if (action) {
                if (module === item.module && action === item.action) {
                    status = true
                }
            } else {
                if (module === item.module) {
                    status = true
                }
            }
            return item
        })
        return status
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
        checkPermission,
    }
}

export default useAuth
